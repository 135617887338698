import React from "react";
import SplitType from 'split-type';

export const splitText = (text) => {
  if (typeof document === "undefined") {
    return text; // Return plain text for SSR
  }

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = text;

  const splitInstance = new SplitType(tempDiv, { types: "words, chars" });

  let globalCharIndex = 0;

  return Array.from(splitInstance.words).map((word, wordIndex) => (
    <div className="word-wrap">
      <div className="word-top" hoverstagger='text'>
        <span className='word' key={`word-${wordIndex}`}>
          {Array.from(word.textContent).map((char, charIndex) => {
            const currentCharIndex = globalCharIndex++
            return (
              <div
                className="char"
                style={{ "--char-index": currentCharIndex }}
                key={`${wordIndex}-${currentCharIndex}`}>
                {char}
              </div>
            )
          })}
        </span>
      </div>
      <div className="word-bottom" hoverstagger='text'>
        <span className='word' key={`word-${wordIndex}`}>
        {Array.from(word.textContent).map((char, charIndex) => {
          const currentCharIndex = globalCharIndex++
          return (<div
              className="char"
              style={{ "--char-index": currentCharIndex }}
              key={`${wordIndex}-${currentCharIndex}`}
            >
              {char}
            </div>)
        })}
      </span>
      </div>
    </div>))
};
