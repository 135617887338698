import React, { useEffect, useState } from "react"
import Link from '../utils/link';
import Form from './contact-form';
import { splitText } from "../utils/split-text";
import Logo from '../assets/images/logo.svg';
import { gsap } from "gsap"

const Header = () => {
  const [offCanvas, setOffCanvas] = useState(false);
  const [contact, setContact] = useState(false);

  const hideOffCanvas = () => setOffCanvas(false);
  const toggleOffCanvas = () => setOffCanvas(prevState => !prevState);
  const toggleContact = () => setContact(prevState => !prevState);

  const props = {
    onClick: hideOffCanvas,
    activeClassName: 'active',
  };

  // useEffect(() => {
  //   document.querySelectorAll('.header__contact').forEach((link) => {
  //     const text1 = link.querySelector("[hoverstagger='text']:nth-child(1)")
  //     const text2 = link.querySelector("[hoverstagger='text']:nth-child(2)")
  //
  //     const tl = gsap.timeline({ paused: true })
  //     tl.to(text1.querySelectorAll(".char"), {
  //       yPercent: -100,
  //       duration: 0.3,
  //       stagger: { amount: 0.2 },
  //     })
  //     tl.from(
  //       text2.querySelectorAll(".char"),
  //       {
  //         yPercent: 100,
  //         duration: 0.3,
  //         stagger: { amount: 0.2 },
  //       },
  //       0
  //     )
  //
  //     link.addEventListener("mouseenter", () => tl.restart())
  //     link.addEventListener("mouseleave", () => tl.reverse())
  //   })
  // }, [])

  return (
    <>
      <header className={`header ${offCanvas ? 'header--off-canvas' : ''}`}>
        <div className='header__inner'>
          <button onClick={toggleContact} className='header__contact'>{splitText('Contact')}</button>
          <Link to='/' title='Site Name' className='header__logo' {...props}>
            <img src={Logo} alt={`Xmedia`} />
          </Link>
          <button
            onClick={toggleOffCanvas}
            className={offCanvas ? 'header__hamburger active' : 'header__hamburger'}
          >
            <span className='lines'></span>
          </button>
        </div>
      </header>
      <div className={`off-canvas ${offCanvas ? 'off-canvas--active' : ''}`}>
        <div className='off-canvas__inner'>
          <nav className='off-canvas__nav'>
            <ul>
              <li><Link to='/' {...props}>{splitText('Films')}</Link></li>
              <li><Link to='/photos/' {...props}>{splitText('Photos')}</Link></li>
              <li><Link to='/about/' {...props}>{splitText('About')}</Link></li>
              <li>
                <button onClick={toggleContact}>
                  {splitText('Contact')}
                </button>
              </li>
            </ul>
          </nav>
          <div className='off-canvas__social'>
            <ul>
              <li>Let's Talk</li>
              <li><Link to='mailto:bookings@x-media.com.au'>{splitText('bookings@x-media.com.au')}</Link></li>
              <li><Link to='tel:+61415311721'>{splitText('+61 415 311 721')}</Link></li>
            </ul>
            <ul>
              <li>Connect</li>
              <li><Link to='https://vimeo.com/xmediavideos'>Vimeo</Link></li>
              <li><Link to='https://www.instagram.com/xmedia/'>Instagram</Link></li>
              <li><Link to='https://www.tiktok.com/@x_media'>TikTok</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`contact ${contact ? 'active' : ''}`}>
        <div className='contact__inner'>
          <button onClick={toggleContact} className='contact__close'>
            Close
            <span className='lines'></span>
          </button>
          <h3>Let’s make magic happen.</h3>
          <Form />
        </div>
      </div>
    </>
  );
};

export default Header;