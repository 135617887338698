import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from '../components/header'
import { gsap } from "gsap"

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  useEffect(() => {
    document.querySelectorAll('.word-wrap').forEach((link) => {
      const text1 = link.querySelector("[hoverstagger='text']:nth-child(1)");
      const text2 = link.querySelector("[hoverstagger='text']:nth-child(2)");
  
      if (!text1 || !text2) return;
  
      // Kill any existing animations to prevent duplication
      gsap.killTweensOf(text1.querySelectorAll(".char"));
      gsap.killTweensOf(text2.querySelectorAll(".char"));
  
      // Define animation timeline
      const tl = gsap.timeline({ paused: true, defaults: { ease: "power2.out" } });
  
      tl.to(text1.querySelectorAll(".char"), {
        yPercent: -100,
        duration: 0.3,
        stagger: { amount: 0.2 },
      })
      .from(
        text2.querySelectorAll(".char"),
        {
          yPercent: 100,
          duration: 0.3,
          stagger: { amount: 0.2 },
        },
        0
      );
  
      const onMouseEnter = () => {
        tl.restart(); // Restart ensures the animation starts from the beginning
      };
  
      const onMouseLeave = () => {
        tl.reverse(); // Reverse to restore the initial state correctly
      };
  
      link.addEventListener("mouseenter", onMouseEnter);
      link.addEventListener("mouseleave", onMouseLeave);
  
      return () => {
        link.removeEventListener("mouseenter", onMouseEnter);
        link.removeEventListener("mouseleave", onMouseLeave);
      };
    });
  }, [location.pathname]);    

  return (
      <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        wp {
          siteOptions {
            acf {
              landing {
                title
                video
                mobileVideo
              }
            }
          }
        }
      }
    `}
    render={data => {

      return (
        <>
          <Header />
          <main>{ children }</main>
        </>
      )
    }
    }
  />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
